.pagination {
  @include flex(center, center, row);
  max-width: 800px;
  margin: 0 auto;
  gap: 20px;
  margin-bottom: 100px;
  margin-top: 2rem;

  .btn {
    color: #fff;
    font-size: 1.2rem;
    padding: 14px;
    @include flex(center, center, row);
    cursor: pointer;

    &.back {
      border-radius: 25px 0px 0px 25px;
      background: #b9b9b9;
    }

    &.forward {
      border-radius: 0px 25px 25px 0px;
      background: var(
        --Gradient,
        linear-gradient(90deg, #359ad4 19.92%, #224099 100%)
      );
    }
  }

  .page-num {
    color: #b9b9b9;
    text-align: center;
    font-family: Helvetica;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;

    &.active {
      color: #000;
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
}

@include for-big-desktop-up {
}
