.view-vip-tip-page {
  .main-content {
    width: 100%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 60px;
    background: #f5f5f5;
    overflow-y: scroll;
    padding: 24px;
    padding-bottom: 100px;

    &::-webkit-scrollbar {
      display: none;
    }

    // TIP AND BTN
    .post-btn {
      margin-bottom: 40px;
      @include flex(center, flex-start, row);

      svg {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        cursor: pointer;
      }

      .title {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #303032;
      }
    }

    .post-details {
      background: #ffffff;
      border-radius: 14px;
      padding: 30px 12px;
      margin-bottom: 40px;

      .form-control {
        label {
          font-size: clamp(1rem, 3vw, 1.2rem);
          margin-bottom: 1rem;
          font-weight: 500;
          span.status {
            text-transform: capitalize;
            color: inherit;
          }
        }

        h3 {
          font-size: clamp(0.9rem, 3vw, 1rem);
          border: 1px solid #d9d9d9;
          background: #f5f5f5;
          padding: 0.5rem 0.5rem;
          margin-top: 0.5rem;
          border-radius: 5px;
          text-transform: capitalize;
          font-weight: 400;
        }

        .content {
          font-size: clamp(0.8rem, 3vw, 0.9rem);
          line-height: 32px;
          border: 1px solid #d9d9d9;
          background: #f5f5f5;
          padding: 0.5rem 0.5rem;
          border-radius: 5px;
          margin-top: 0.5rem;

          p {
            line-height: 24px;
            margin-bottom: 0.5rem;
          }
        }

        .img {
          margin-top: 0.5rem;
          margin-bottom: 1rem;
        }
      }

      .btns {
        button {
          margin-top: 1rem;
        }
      }
    }
  }
}

@include for-smallest-phones {
  .view-vip-tip-page {
    .main-content {
      padding: 24px 14px;
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .view-vip-tip-page {
    .main-content {
      padding: 24px 40px;

      .post-btn {
        margin-bottom: 40px;
      }

      .post-details {
        .btns {
          @include flex(center, space-between, row);
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .view-vip-tip-page {
    .main-content {
      width: 80%;
      top: 72px;
      padding: 70px 30px;

      .post-btn {
        svg {
          display: none;
        }
      }

      .post-details {
        padding: 30px 24px;
        width: 80%;
        margin: auto;
        margin-bottom: 100px;
      }

      &.back {
        z-index: -2;
      }
    }
  }
}

@include for-big-desktop-up {
}
