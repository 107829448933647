.social-media-links-page {
  .main-content {
    width: 100%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 60px;
    background: #f5f5f5;
    overflow-y: scroll;
    padding: 24px;
    padding-bottom: 100px;

    &::-webkit-scrollbar {
      display: none;
    }

    // LINKS AND BTN
    .links-btn {
      margin-bottom: 40px;
      @include flex(center, flex-start, row);

      svg {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        cursor: pointer;
      }

      .title {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #303032;
      }
    }

    .loader-container {
      height: 40vh;
      @include flex(center, center, row);
    }

    .links-container {
      background: #ffffff;
      border-radius: 14px;
      padding: 30px 12px;
      margin-bottom: 100px;

      .link {
        border: 1px solid #f4f4f4;
        margin-bottom: 2rem;
        padding: 0.5rem 0.5rem;
        border-radius: 5px;

        .info {
          margin-bottom: 0.5rem;

          h3 {
            font-size: clamp(1rem, 3vw, 1.2rem);
            font-weight: 500;
            margin-bottom: 0.2rem;
            text-transform: capitalize;
          }

          p {
            font-size: clamp(0.8rem, 3vw, 0.9rem);
            opacity: 0.9;
          }
        }

        button {
          padding: 8px 14px;
          font-size: 0.8rem;
        }
      }
    }
  }
}

@include for-smallest-phones {
  .social-media-links-page {
    .main-content {
      padding: 24px 14px;
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .social-media-links-page {
    .main-content {
      padding: 24px 40px;

      // LINK AND BTN
      .link-btn {
        margin-bottom: 40px;
      }
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .social-media-links-page {
    .main-content {
      width: 80%;
      top: 72px;
      padding: 70px 30px;

      .links-btn {
        svg {
          display: none;
        }
      }

      .links-container {
        padding: 30px 24px;
        width: 80%;
        margin: auto;
        margin-bottom: 30px;

        .link {
          padding: 0.5rem 1rem;
          @include flex(center, space-between, row);

          .info {
            margin-bottom: 0;
          }
        }
      }

      &.back {
        z-index: -2;
      }
    }
  }
}

@include for-big-desktop-up {
}
