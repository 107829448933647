.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  @include flex(flex-start, center, row);

  &::-webkit-scrollbar {
    display: none;
  }

  .modal {
    width: 90%;
    background-color: #fff;
    border-radius: 10px;
    padding: 2rem 14px;
    margin-top: 4rem;

    .close-btn {
      @include flex(center, flex-end, row);

      svg {
        color: red;
        cursor: pointer;
      }
    }

    button {
      padding: 4px 30px;
      width: 100%;
      margin-top: 1.5rem;

      &[disabled] {
        background-color: grey;
      }
    }

    #cke_editor1 {
      margin-top: 0.5rem;
    }

    input[type='file'] {
      height: 55px;
    }
  }

  .add-bookie {
    margin-bottom: 100px;

    .form-control {
      margin-bottom: 1rem;

      input {
        height: 38px;
        margin-top: 0.4rem;
      }
    }
  }

  .delete-vip-tips,
  .delete-all {
    p {
      font-size: clamp(0.9rem, 3vw, 1rem);
      text-align: center;
      margin-bottom: 1rem;
    }

    .btns {
      @include flex(center, space-between, row);

      button {
        &:first-child {
          margin-right: 2rem;
        }
      }
    }
  }

  .update-customer-vip-status {
    .form-control {
      select {
        width: 100%;
        border: 1px solid #f4f4f4;
        height: 45px;
        margin-top: 1rem;
        border-radius: 10px;
        padding: 0 7px;
        background-color: #d9d6d6;
      }
    }
  }
}

.modal-container#delete-container {
  @include flex(center, center, row);
}

@include for-smallest-phones {
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .modal-container {
    .modal {
      width: 30%;
      background-color: #fff;
      border-radius: 10px;
      padding: 2rem 14px;

      .close-btn {
        svg {
          font-size: 1.5rem;
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
