.login-page {
  @include flex(center, flex-start, column-reverse);
  justify-content: space-between;
  padding: 50px 24px;
  width: 100%;
  margin: auto;

  h3 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: $textColor1;
  }

  .form-container {
    width: 100%;
    padding-bottom: 1rem;

    .password-container {
      @include flex(center, flex-start, row);
      position: relative;
      margin-top: 10px;
      width: 100%;

      input {
        height: 100%;
        margin-top: 0;
      }

      svg {
        position: absolute;
        right: 1rem;
        cursor: pointer;
      }
    }

    button {
      width: 100%;
      margin-top: 32px;
      font-weight: 600;
    }
  }
}

@include for-smallest-phones {
  .login-page {
    padding: 50px 14px;
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .login-page {
    padding: 50px 40px;
    width: 80%;

    h3 {
      font-size: 24px;
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .login-page {
    padding: 50px 90px;
    flex-direction: row;
    width: 100%;
    height: 100vh;

    h3 {
      font-size: 30px;
      line-height: 60px;
    }

    .form-container {
      width: 50%;
      border-bottom: none;
      border-right: 1px solid #d9d9d9;
      padding: 2rem 0;
      padding-right: 30px;

      button {
        width: 100%;
      }
    }

    .login-img {
      width: 50%;
    }
  }
}

@include for-big-desktop-up {
}
