.profile-modal {
    position: absolute;
    min-width: 185px;
    padding: 24px 18px;
    background: #FFFFFF;
    border: 1.5px solid #EAEDF3;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    top: 50px;
    z-index: 20;

    ul {
        li {
            margin: 16px 0;
            @include flex(center, flex-start, row);
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #434856;
            cursor: pointer;

            .icon {
                font-size: 1.2rem;
                margin-right: 10px;
            }

            &.red {
                color: #F26E6E;
            }

        }
    }
}

@include for-smallest-phones {}

@include for-phone-only {}

@include for-tablet-potrait-up {}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .profile-modal {
        top: 70px;
        z-index: 20;

        ul {
            li {
                font-size: 16px;

                .icon {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

@include for-big-desktop-up {}