.news-updates-page {
  .main-content {
    width: 100%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 60px;
    background: #f5f5f5;
    overflow-y: scroll;
    padding: 24px;

    &::-webkit-scrollbar {
      display: none;
    }

    .table {
      background: #ffffff;
      border-radius: 14px;
      overflow-x: scroll;
      padding: 30px 15px;

      .headers {
        @include grid(2, 1fr, 10px);
        background: #fafafa;
        border-radius: 6px;
        padding: 17px 24px;
        width: 600px;

        p {
          text-align: center;

          &:first-child {
            text-align: start;
          }

          &.date-posted {
            text-align: end;
          }
        }
      }

      .post-info {
        @include grid(2, 400px 200px, 10px);
        border-radius: 6px;
        padding: 17px 24px;
        border-bottom: 1px solid #f4f4f4;
        margin: 10px 0;

        p {
          text-align: center;
          font-weight: 400;
          line-height: 17px;
          color: #737b7b;
          padding: 0.5rem;
          font-size: clamp(0.9rem, 3vw, 1rem);

          &:first-child {
            text-align: start;
          }
        }
      }
    }
  }
}

@include for-smallest-phones {
  .news-updates-page {
    .main-content {
      padding: 24px 14px;
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .news-updates-page {
    .main-content {
      width: 80%;
      top: 72px;
      padding: 70px 30px;

      .table {
        overflow-x: hidden;
        padding: 30px;

        .headers {
          @include grid(2, 1fr, 10px);
          width: 100%;

          p.date-posted {
            text-align: end;
          }
        }

        .post-info {
          @include grid(2, 1fr, 10px);

          p.date-posted {
            text-align: end;
          }
        }
      }

      &.back {
        z-index: -2;
      }
    }
  }
}

@include for-big-desktop-up {
}
