.leaderboard-page {
  .main-content {
    width: 100%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 60px;
    background: #f5f5f5;
    overflow-y: scroll;
    padding: 24px;

    &::-webkit-scrollbar {
      display: none;
    }

    .form-control {
      &.leaderboard {
        width: 100%;
        max-width: 300px;

        input {
          background: #fff;
        }
      }
    }

    .table {
      background: #ffffff;
      border-radius: 14px;
      overflow-x: scroll;
      padding: 30px 15px;

      .headers {
        @include grid(4, 200px, 10px);
        background: #fafafa;
        border-radius: 6px;
        padding: 17px 24px;
        width: 850px;

        p {
          text-align: center;

          &:first-child {
            text-align: start;
          }
        }
      }

      .no-players {
        @include flex(center, center, row);
        height: 100px;

        h1 {
          font-size: clamp(1rem, 3vw, 1.3rem);
          font-weight: 500;
          text-align: center;
        }
      }

      .player-info {
        @include grid(5, 200px, 10px);
        border-radius: 6px;
        padding: 17px 24px;
        border-bottom: 1px solid #f4f4f4;
        margin: 10px 0;

        p {
          text-align: center;
          font-weight: 400;
          line-height: 17px;
          color: #737b7b;
          padding: 0.5rem;
          font-size: clamp(0.9rem, 3vw, 1rem);

          &:first-child {
            text-align: start;
          }
        }

        .btns {
          @include flex(center, center, row);
          svg {
            font-size: 1.5rem;
            cursor: pointer;
            margin: 0 0.5rem;

            &.edit {
              color: $primaryColor;
            }

            &.delete {
              color: red;
            }
          }
        }
      }
    }

    .delete-leaderboard {
      @include flex(center, flex-end, row);
      margin-top: 3rem;
      margin-bottom: 100px;
    }
  }
}

@include for-smallest-phones {
  .leaderboard-page {
    .main-content {
      padding: 24px 14px;
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .leaderboard-page {
    .main-content {
      width: 80%;
      top: 72px;
      padding: 70px 30px;

      .table {
        overflow-x: hidden;
        padding: 30px;

        .headers {
          @include grid(4, 1fr, 10px);
          width: 100%;
        }

        .player-info {
          @include grid(4, 1fr, 10px);
        }
      }

      &.back {
        z-index: -2;
      }
    }
  }
}

@include for-big-desktop-up {
}
