.dashboard {
  position: relative;

  .main-content {
    width: 100%;
    position: fixed;
    height: 100vh;
    right: 0;
    top: 60px;
    background: #f5f5f5;
    overflow-y: scroll;
    padding: 24px;

    &::-webkit-scrollbar {
      display: none;
    }

    // CARDS SECTION
    .cards {
      margin-bottom: 30px;
      @include grid(2, 1fr, 10px);
      width: 100%;

      .card {
        height: 232px;
        background: #ffffff;
        border-radius: 10px;
        @include flex(center, center, column);
        padding: 0 0.3rem;

        .img {
          width: 58.45px;
          height: 58.45px;
          margin-bottom: 20px;
          @include flex(center, center, row);
          border-radius: 50%;
          background-color: $primaryColor;

          svg {
            font-size: 1.5rem;
            color: #fff;
          }
        }

        .info {
          text-align: center;

          .name {
            font-weight: 400;
            font-size: clamp(0.8rem, 3vw, 1rem);
            line-height: 21px;
            color: #262d57;
            margin-bottom: 5px;
          }

          .num {
            font-weight: 600;
            font-size: 30px;
            line-height: 36px;
            text-align: center;
            color: #342094;
          }
        }
      }
    }

    // TOTAL NUMBER OF TIPS AVAILABLE ON THE PLATFORM
    .total-tips-available {
      margin-top: 1rem;
      margin-bottom: 150px;
      padding-bottom: 4rem;

      form {
        .form-control {
          label {
            span {
              color: inherit;
              font-size: clamp(0.8rem, 2vw, 0.9rem);
            }
          }
        }
      }
    }

    // UPDATE PASSWORD
    .update-password {
      margin-top: 1rem;
      margin-bottom: 150px;
      padding-bottom: 4rem;

      form {
        .form-control {
          label {
            span {
              color: inherit;
              font-size: clamp(0.8rem, 2vw, 0.9rem);
            }
          }

          .password-container {
            @include flex(center, flex-start, row);
            position: relative;
            margin-top: 10px;
            width: 100%;

            input {
              height: 100%;
              margin-top: 0;
            }

            svg {
              position: absolute;
              right: 1rem;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@include for-smallest-phones {
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .dashboard {
    .main-content {
      padding: 24px 40px;
    }
  }
}

@include for-tablet-landscape-up {
  .dashboard {
    .main-content {
      padding: 24px 60px;
    }
  }
}

@include for-desktop-up {
  .dashboard {
    .main-content {
      width: 80%;
      right: 0;
      top: 72px;
      padding: 70px 30px;

      // CARDS SECTION
      .cards {
        margin-bottom: 3rem;
        @include grid(4, 1fr, 20px);
      }

      .total-tips-available {
        margin-bottom: 0;

        form {
          .form-control {
            width: 50%;
          }
        }
      }

      // UPDATE PASSWORD
      .update-password {
        form {
          .form-control {
            .password-container {
              width: 50%;
            }
          }
        }
      }

      &.back {
        z-index: -2;
      }
    }
  }
}

@include for-big-desktop-up {
}
