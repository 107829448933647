.edit-daily-tip-page {
  .main-content {
    width: 100%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 60px;
    background: #f5f5f5;
    overflow-y: scroll;
    padding: 24px;
    padding-bottom: 100px;

    &::-webkit-scrollbar {
      display: none;
    }

    // TIP AND BTN
    .title-btn {
      margin-bottom: 40px;
      @include flex(center, flex-start, row);

      svg {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        cursor: pointer;
      }

      .title {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #303032;
      }
    }

    .ticket-details {
      background: #ffffff;
      border-radius: 14px;
      padding: 30px 12px;
      margin-bottom: 40px;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #182524;
      }

      .btns {
        margin-bottom: 2rem;
      }

      .form-control {
        label {
          span.status {
            text-transform: capitalize;
            color: inherit;
          }

          span.current {
            font-size: clamp(0.8rem, 3vw, 0.9rem);
            color: #182524;
            opacity: 0.7;
          }
        }

        h3 {
          font-size: clamp(0.9rem, 3vw, 1rem);
          border: 1px solid #d9d9d9;
          background: #f5f5f5;
          padding: 0.5rem 0.5rem;
          margin-top: 0.5rem;
          border-radius: 5px;
          text-transform: capitalize;
          font-weight: 400;
        }

        select {
          width: 100%;
          height: 40px;
          margin-top: 0.5rem;
          padding: 0 0.5rem;
          background-color: #fff;
          border: 1px solid #d9d9d9;
          border-radius: 5px;
        }

        button {
          margin-top: 1rem;
        }
      }

      .bookies {
        padding: 1rem 0.5rem;

        &.current {
          margin: 2rem 0;
        }

        h3.title {
          font-size: clamp(0.9rem, 3vw, 1rem);
          margin-bottom: 1rem;
        }

        .bookie {
          padding: 0.5rem 0.5rem;
          border-radius: 5px;
          border: 2px solid #f4f4f4;
          margin-bottom: 1rem;
          @include grid(1, 1fr, 10px);

          h5 {
            font-size: clamp(0.8rem, 3vw, 1rem);
            font-weight: 400;
            text-transform: capitalize;
          }

          svg {
            font-size: 1.1rem;
            color: red;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@include for-smallest-phones {
  .edit-daily-tip-page {
    .main-content {
      padding: 24px 14px;
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .edit-daily-tip-page {
    .main-content {
      padding: 24px 40px;

      .customer-btn {
        margin-bottom: 40px;
      }
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .edit-daily-tip-page {
    .main-content {
      width: 80%;
      top: 72px;
      padding: 70px 30px;

      .title-btn {
        svg {
          display: none;
        }
      }

      .ticket-details {
        padding: 30px 24px;
        width: 80%;
        margin: auto;
        margin-bottom: 100px;
      }

      .ticket-details {
        .row {
          @include flex(center, space-between, row);

          .form-control {
            width: 100%;
            margin-bottom: 20px;

            &:last-child {
              margin-left: 2rem;
            }
          }
        }

        .bookies {
          .bookie {
            @include grid(4, 1fr, 10px);

            .code {
              text-align: center;
            }

            .link {
              text-align: end;
            }

            .delete {
              @include flex(center, center, row);
            }
          }
        }
      }

      &.back {
        z-index: -2;
      }
    }
  }
}

@include for-big-desktop-up {
}
