.customers-page {
  .main-content {
    width: 100%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 60px;
    background: #f5f5f5;
    overflow-y: scroll;
    padding: 24px;

    &::-webkit-scrollbar {
      display: none;
    }

    .table {
      background: #ffffff;
      border-radius: 14px;
      overflow-x: scroll;
      padding: 30px 15px;
      margin-bottom: 100px;

      .headers {
        @include grid(5, 180px, 10px);
        background: #fafafa;
        border-radius: 6px;
        padding: 17px 24px;
        width: 800px;

        p {
          text-align: center;

          &:first-child {
            text-align: start;
          }
        }
      }

      .customer-info {
        @include grid(5, 180px, 10px);
        border-radius: 6px;
        padding: 17px 24px;
        border-bottom: 1px solid #f4f4f4;
        margin: 10px 0;

        .customer {
          @include flex(center, flex-start, row);

          .img {
            background: #f2e7e1;
            border-radius: 7px;
            width: 39px;
            height: 40px;
            padding: 6px;
          }

          p {
            text-align: start;
          }
        }

        p {
          text-align: center;
          font-weight: 400;
          line-height: 17px;
          color: #737b7b;
          padding: 0.5rem;
          font-size: clamp(0.9rem, 3vw, 1rem);

          &:first-child {
            text-align: start;
          }

          &.customer-name {
            text-transform: capitalize;
          }
        }

        .btns {
          @include flex(center, center, row);
          svg {
            font-size: 1.5rem;
            cursor: pointer;
            margin: 0 0.5rem;

            &.edit {
              color: $primaryColor;
            }

            &.delete {
              color: red;
            }
          }
        }
      }
    }
  }
}

@include for-smallest-phones {
  .customers-page {
    .main-content {
      padding: 24px 14px;
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .customers-page {
    .main-content {
      width: 80%;
      top: 72px;
      padding: 70px 30px;

      .table {
        overflow-x: hidden;
        padding: 30px 10px;

        .headers {
          @include grid(4, 1fr, 10px);
          width: 100%;
        }

        .customer-info {
          @include grid(4, 1fr, 10px);
        }
      }

      &.back {
        z-index: -2;
      }
    }
  }
}

@include for-big-desktop-up {
}
