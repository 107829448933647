.competitions-page {
  .main-content {
    width: 100%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 60px;
    background: #f5f5f5;
    overflow-y: scroll;
    padding: 24px;
    padding-bottom: 100px;

    &::-webkit-scrollbar {
      display: none;
    }

    .title-btn {
      margin-bottom: 40px;
      @include flex(center, flex-start, row);

      svg {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        cursor: pointer;
      }

      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #303032;
      }
    }

    .add-delete-club {
      @include flex(center, flex-end, row);
      margin-bottom: 3rem;

      button {
        &:last-child {
          margin-left: 1.5rem;
        }
      }
    }

    .available-clubs {
      padding-bottom: 100px;
      h2 {
        font-size: clamp(1.1rem, 3vw, 1.4rem);
        margin-bottom: 2rem;
      }

      .grid {
        @include grid(2, 1fr, 20px);

        .item {
          @include flex(center, center, column);
          background-color: #fff;
          padding: 1rem 0.5rem;
          border-radius: 10px;

          .img {
            width: 32px;
            @include flex(center, center, row);
            margin-bottom: 0.8rem;
          }

          .name {
            font-size: clamp(0.9rem, 3vw, 1rem);
            margin-bottom: 0.8rem;
          }

          .del {
            font-size: 1.2rem;
            color: $redColor;
          }
        }
      }

      .no-club {
        @include flex(center, center, row);
        height: 20vh;

        h1 {
          font-size: clamp(1rem, 3vw, 1.2rem);
        }
      }
    }
  }
}

@include for-smallest-phones {
  .competitions-page {
    .main-content {
      padding: 24px 14px;
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .competitions-page {
    .main-content {
      padding: 24px 40px;

      .title-btn {
        margin-bottom: 40px;
      }

      .available-clubs {
        .grid {
          @include grid(3, 1fr, 20px);
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
  .competitions-page {
    .main-content {
      .available-clubs {
        .grid {
          @include grid(4, 1fr, 20px);
        }
      }
    }
  }
}

@include for-desktop-up {
  .competitions-page {
    .main-content {
      width: 80%;
      top: 72px;
      padding: 70px 30px;

      .title-btn {
        svg {
          display: none;
        }
      }

      .available-clubs {
        .grid {
          @include grid(5, 1fr, 20px);
        }
      }

      &.back {
        z-index: -2;
      }
    }
  }
}

@include for-big-desktop-up {
}
