.new-daily-tip-page {
  .main-content {
    width: 100%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 60px;
    background: #f5f5f5;
    overflow-y: scroll;
    padding: 24px;

    &::-webkit-scrollbar {
      display: none;
    }

    // TIP AND BTN
    .title-btn {
      margin-bottom: 40px;
      @include flex(center, flex-start, row);

      svg {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        cursor: pointer;
      }

      .title {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #303032;
      }
    }

    // TICKET DETAILS BOX
    .ticket-details {
      background: #ffffff;
      border-radius: 14px;
      padding: 30px 24px;
      margin-bottom: 200px;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #182524;
        margin-bottom: 24px;
      }

      form {
        .form-control {
          margin-bottom: 30px;
          position: relative;

          input {
            height: 40px;
          }

          select {
            width: 100%;
          }
        }

        .bookies {
          margin-bottom: 2rem;

          h3.title {
            font-size: clamp(0.9rem, 3vw, 1rem);
            margin-bottom: 1rem;
          }

          .bookie {
            padding: 0.5rem 0.5rem;
            border-radius: 5px;
            border: 2px solid #f4f4f4;
            margin-bottom: 1rem;
            @include grid(2, 1fr, 10px);

            h5 {
              font-size: clamp(0.8rem, 3vw, 0.9rem);
              font-weight: 400;
              text-transform: capitalize;
            }

            .name {
              @include flex(center, flex-start, row);
            }

            .del {
              @include flex(center, flex-start, row);
              color: red;

              svg {
                cursor: pointer;
                font-size: 1.3rem;
              }
            }
          }
        }

        .add-bookie {
          margin-bottom: 1rem;

          button {
            padding: 8px 20px;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

@include for-smallest-phones {
  .new-daily-tip-page {
    .main-content {
      padding: 14px;
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .new-daily-tip-page {
    .main-content {
      // TICKET
      .ticket-details {
        margin-bottom: 40px;
      }
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .new-daily-tip-page {
    .main-content {
      width: 80%;
      top: 72px;
      padding: 70px 30px;

      // TIP AND BTN
      .title-btn {
        svg {
          display: none;
        }
      }

      // TICKET DETAILS BOX
      .ticket-details {
        padding: 30px 40px;
        width: 70%;
        margin: auto;
        margin-bottom: 200px;

        form {
          .bookies {
            margin-bottom: 2rem;

            .bookie {
              @include grid(4, 1fr, 10px);

              .prediction {
                text-align: center;
              }

              .time {
                text-align: end;
              }

              .del {
                @include flex(center, center, row);
              }
            }
          }
        }
      }

      &.back {
        z-index: -2;
      }
    }
  }
}

@include for-big-desktop-up {
}
