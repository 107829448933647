.messages-page {
  .main-content {
    width: 100%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 60px;
    background: #f5f5f5;
    overflow-y: scroll;
    padding: 24px;

    &::-webkit-scrollbar {
      display: none;
    }

    .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #303032;
      margin-bottom: 40px;
    }

    .message-box {
      background: #ffffff;
      border-radius: 14px;
      padding: 30px 12px;
      margin-bottom: 200px;

      .subject {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #182524;
        margin-bottom: 30px;
      }

      .name {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #3c4258;
        font-weight: 500;
        margin-bottom: 20px;
        text-transform: capitalize;
      }

      .email,
      .phone {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #3c4258;
        margin-bottom: 10px;
      }

      .date {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #3c4258;
        margin-bottom: 30px;
      }

      .message-body {
        background: #f5f5f5;
        border: 1px solid rgba(0, 8, 28, 0.05);
        border-radius: 6px;
        padding: 12px 12px 24px 12px;
        margin-bottom: 30px;
      }

      form {
        .form-control {
          margin-bottom: 30px;

          label {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #3c4258;
          }

          textarea {
            border: 1px solid #f5f5f5;
            min-height: 200px;
            resize: none;
          }
        }

        .format-options {
          margin-bottom: 30px;
          width: 50%;
          @include flex(center, space-between, row);

          .icon {
            font-size: 1.3rem;
          }
        }

        .btns {
          margin-bottom: 30px;

          button {
            &:last-child {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

@include for-smallest-phones {
  .messages-page {
    .main-content {
      padding: 24px 14px;
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .messages-page {
    .main-content {
      padding: 24px 40px;

      .message-box {
        padding: 30px 40px;
        margin-bottom: 100px;

        form {
          .form-control {
            textarea {
              min-height: 150px;
            }
          }

          .format-options {
            width: 30%;
          }
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .messages-page {
    .main-content {
      width: 80%;
      top: 72px;
      padding: 70px 30px;

      .message-box {
        width: 70%;
        margin: auto;
        margin-bottom: 100px;
        padding: 30px;

        form {
          .btns {
            @include flex(center, space-between, row);

            button {
              width: 120px;

              &:last-child {
                margin-left: 10px;
              }
            }
          }
        }
      }

      &.back {
        z-index: -2;
      }
    }
  }
}

@include for-big-desktop-up {
}
