// FONT FROM GOOGLE FONTS
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

@import './variables';
@import './mixins';
@import './forms';
@import './pagination';

// COMPONENT STYLES
@import '../components/Navbar/navbar';
@import '../components/Alert-Modal/alertModal';
@import '../components/Functions-Bar/functionsBar';
@import '../components/Sidebar/sidebar';
@import '../components/Loader/loader';
@import '../components/Profile-Modal/profileModal';
@import '../components/Modals/modals';

// PAGES STYLES
@import '../pages/Auth/auth';
@import '../pages/Dashboard/dashboard';
@import '../pages/Customers/customers';
@import '../pages/Customers/viewCustomer';

// daily tips
@import '../pages/daily-tips/daily-tips';
@import '../pages/daily-tips/edit-daily-tip';
@import '../pages/daily-tips/new-daily-tip';

// daily tips
@import '../pages/todays-tips/todays-tips';
@import '../pages/todays-tips/edit-todays-tip';
@import '../pages/todays-tips/new-todays-tip';

// accumulator tips
@import '../pages/accumulator-tips/accumulator-tips';
@import '../pages/accumulator-tips/edit-acca-tip';
@import '../pages/accumulator-tips/new-acca-tip';

// match of the day
@import '../pages/match-of-the-day/motd';
@import '../pages/match-of-the-day/new-motd';

// leaderboard
@import '../pages/leaderboard/leaderboard';
@import '../pages/leaderboard/add-player';
@import '../pages/leaderboard/edit-player';

// clubs
@import '../pages/clubs/clubs';

// competitions
@import '../pages/competitions/competitions';

@import '../pages/Social-Media-Links/social-media-links';

// news updates
@import '../pages/news-updates/news-updates';
@import '../pages/news-updates/view-post';
@import '../pages/news-updates/new-post';

// ads
@import '../pages/ads/ads';

// messages
@import '../pages/contact-messages/messages';
@import '../pages/contact-messages/view-message';

// affiliates
@import '../pages/affiliates/affiliates';

// pop-ups
@import '../pages/popups/popups';

// gifs
@import '../pages/gifs/gifs';

// admins
@import '../pages/admins/admins';
@import '../pages/admins/new-admin';
@import '../pages/admins/admins';

// extras
@import '../pages/extras/extras';

// RESETS
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  max-width: 1800px;
  margin: auto;
  color: $textColor1;
  font-family: 'Hanken Grotesk', sans-serif;
  src: url('../assets/HankenGrotesk-VariableFont_wght.ttf');
}

ul {
  list-style-type: none;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-self: center;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
textarea,
select {
  font-size: 16px;
  outline: none;
  border: none;
}

select {
  text-transform: capitalize;
}

button,
.button {
  padding: 8px 30px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 1px 1px rgba(123, 123, 123, 0.16);
  color: #717171;
  border: none;
  @include flex(center, center, row);
  font-family: 'Poppins', sans-serif;

  &.primary {
    background-color: $primaryColor;
    color: #ffffff;
  }

  &.secondary {
    background: $secondaryColor;
    color: #fff;
  }

  &.transparent {
    background: transparent;
    border: 1px solid #d9d9d9;
    box-shadow: none;
  }

  &.red {
    background: red;
    box-shadow: none;
    color: #fff;
  }

  &.grey {
    background-color: #717171;
    color: #fff;
    box-shadow: none;
  }

  img {
    margin-left: 10px;
    height: 20px;
    width: 20px;
  }

  &[disabled] {
    background-color: #717171;
  }
}

// UTILITY CLASSES
.underline {
  border: 1px solid #f2f3f3;
}

.border {
  border: 2px solid red;
}
