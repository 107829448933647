.form-control {
  margin: 12px 0;
  margin-bottom: 1.5rem;

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    span {
      color: $redColor;
    }

    &.error {
      color: $redColor;
    }
  }

  input {
    width: 100%;
    height: 48px;
    margin-top: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 16px;
    outline: none;
    background: #f5f5f5;
  }

  select {
    width: 100%;
    // height: 48px;
    margin-top: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 16px;
    outline: none;
    background: #f5f5f5;
  }

  textarea {
    width: 100%;
    max-width: 100%;
    margin-top: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 16px;
    outline: none;
    resize: none;
    background: #f5f5f5;
  }

  .password-container {
    @include flex(center, flex-start, row);
    position: relative;
    margin-top: 10px;
    width: 100%;

    input {
      height: 100%;
      margin-top: 0;
    }

    svg {
      position: absolute;
      right: 1rem;
      cursor: pointer;
    }
  }
}

@include for-smallest-phones {
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .form-control {
    margin: 12px 0;
    margin-bottom: 1.5rem;

    label {
      font-size: 16px;
    }

    input {
      width: 100%;
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
}

@include for-big-desktop-up {
}
