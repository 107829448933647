.new-post-page {
  .main-content {
    width: 100%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 60px;
    background: #f5f5f5;
    overflow-y: scroll;
    padding: 24px;

    &::-webkit-scrollbar {
      display: none;
    }

    // POST AND BTN
    .post-btn {
      margin-bottom: 40px;
      @include flex(center, flex-start, row);

      svg {
        font-size: 1.2rem;
        margin-right: 0.5rem;
        cursor: pointer;
      }

      .title {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #303032;
      }
    }

    // TICKET DETAILS BOX
    .post-details {
      background: #ffffff;
      border-radius: 14px;
      padding: 30px 24px;
      margin-bottom: 200px;

      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #182524;
        margin-bottom: 24px;
      }

      form {
        .form-control {
          margin-bottom: 30px;
          position: relative;

          #cke_editor1 {
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}

@include for-smallest-phones {
  .new-post-page {
    .main-content {
      padding: 14px;
    }
  }
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .new-post-page {
    .main-content {
      // TICKET
      .post-details {
        margin-bottom: 40px;
      }
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .new-post-page {
    .main-content {
      width: 80%;
      top: 72px;
      padding: 70px 30px;

      .post-btn {
        svg {
          display: none;
        }
      }

      .post-details {
        padding: 30px 40px;
        width: 70%;
        margin: auto;
        margin-bottom: 200px;

        form {
        }
      }

      &.back {
        z-index: -2;
      }
    }
  }
}

@include for-big-desktop-up {
}
